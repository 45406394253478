


import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import { TChatMessageContext } from '@/_modules/chat/types/chat-message.type';
import { TContact } from '@/_types/contact.type';

const DIRECT_MESSAGE_MAX_LENGTH = 1000;
const TEXTAREA_LINE_HEIGHT = 17; // N.B. If you change LINE_HEIGHT and MAX_LINES,
const TEXTAREA_MAX_LINES = 8; // you'll have to change CSS correspondingly.

const chatStore = namespace('chatStore');
const promoPageStore = namespace('promoPageStore');

@Component({
  components: {
    IconArrowLeft
  }
})
export default class TextChatMessageComposer extends Vue {

  @promoPageStore.Getter('contact') public readonly myself: TContact;
  @chatStore.Action('sendChatGroupTextMessage') public sendChatGroupTextMessage: (params: { groupId: string; message: string; context: TChatMessageContext }) => void;

  public newMessageText: string = '';
  public isFieldWrapFocused: boolean = false;
  public isNewMessageTextareaTransitioning: boolean = false;
  public isSendingMessage: boolean = false;

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  @Prop({ type: String })
  public readonly groupId: string;

  public get newMessageTextProcessed(): string {
    let result: string = this.newMessageText;
    // escape html
    result = result.replace(/</g, '&lt;');

    // nl2br
    result = result.replace(/\n/g, '<br>');

    return result;
  }

  public get isSendMessageButtonDisabled(): boolean {
    return this.isSendingMessage || this.isDirectMessageLengthExceeded || (this.newMessageText === '');
  }

  public get isDirectMessageLengthExceeded(): boolean {
    return this.newMessageText.length >= DIRECT_MESSAGE_MAX_LENGTH;
  }

  public get myFullName(): string {
    if (!this.myself) {
      return '';
    }
    const name = this.myself.name ? this.myself.name.trim() : '';
    const surname = this.myself.surname ? this.myself.surname.trim() : '';

    return [name, surname].join(' ').trim();

  }

  public get textareaAutoHeight(): string {
    const msg: string = this.newMessageText; // This line creates a dependency, making VueJS re-read newMessageText.
    const defaultHeight = TEXTAREA_LINE_HEIGHT.toFixed(0) + 'px';
    let result = defaultHeight;
    const heightMonitor: HTMLDivElement = this.$refs.newMessageTextAutoHeightDouble as HTMLDivElement;
    if (!heightMonitor || (msg === '')) {
      return result;
    }
    const lineBreakHeight: number = msg.split(/\n/).length * TEXTAREA_LINE_HEIGHT;
    const boundingHeight: number = heightMonitor.getBoundingClientRect().height;

    result = Math.max(lineBreakHeight, boundingHeight).toFixed(0);

    if (result !== '0') {
      result = result + 'px';
    } else {
      result = defaultHeight;
    }
    return result;
  }

  public onButtonMessageSendClick(): void {
    this.sendMessage();
  }

  private async sendMessage(): Promise<void> {

    const messageText = this.newMessageText.trim();

    if (!messageText || this.isSendingMessage) {
      return;
    }

    this.isSendingMessage = true;

    const chatMessageContext: TChatMessageContext = {
      name: this.myFullName,
      photo_url: this.myself.photo_url ? this.myself.photo_url : '',
      contact_id: this.myself.id,
      platform: 'Web'
    };

    this.sendChatGroupTextMessage({
      groupId: this.groupId,
      message: messageText,
      context: chatMessageContext
    });
    this.clearTextArea();
    this.isSendingMessage = false;
    this.focusOnTextArea();
  }

  public focusOnTextArea(): void {
    if (!this.$refs.newMessageTextarea) {
      return;
    }
    this.$nextTick(() => {
      (this.$refs.newMessageTextarea as HTMLTextAreaElement).focus();
    });
  }

  private onFieldWrapClick(): void {
    this.focusOnTextArea();
  }

  private setFieldWrapFocused(): void {
    this.isFieldWrapFocused = true;
  }

  private setFieldWrapUnfocused(): void {
    this.isFieldWrapFocused = false;
  }

  private onNewMessageTextareaTransitionStart(): void {
    this.isNewMessageTextareaTransitioning = true;
  }

  private onNewMessageTextareaTransitionEnd(): void {
    this.isNewMessageTextareaTransitioning = false;
  }

  private getNewMessageTextareaClasses(): any {
    return {
      'is-transitioning': this.isNewMessageTextareaTransitioning,
      'is-over-height-limit': parseInt(this.textareaAutoHeight, 10) >= TEXTAREA_LINE_HEIGHT * TEXTAREA_MAX_LINES,
      'is-empty': this.newMessageText === '',
    };
  }

  private clearTextArea(): void {
    this.newMessageText = '';
  }

}
